@import 'styles/_theme.scss';

.inlineBlock {
  display: inline-block;
}

.addPadding {
  padding-top: $ss-24;
}

.switch {
  padding-left: $ss-10;
}
.switchLabel {
  padding-left: $ss-20;
  color: $neutralDark;
  font-weight: 600;
}

.description {
  padding-left: 62px;
  color: $neutralDark;
}

.checkbox {
  padding: 0;
  margin-right: $ss-8;

  &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: $primaryDark !important;
  }
}

.checkboxLabel {
  color: $neutralDark;
  display: flex;
  padding-left: $ss-20;
}

.checkboxDescription {
  padding-left: $ss-58;
  color: $neutralDark;
  font-size: $ts-14;
}

.radioGroupLabel {
  color: $neutralDark;
}

.radioGroup {
  margin-left: $ss-10;
}

.identifierText {
  font-size: $ts-14;
  color: $neutralDark;
  margin-bottom: $ss-6;
}

.helperText {
  font-size: $ts-14;
  color: $neutral;
  margin-bottom: $ss-6;
}

.swap {
  margin-top: -$ss-42;
  padding-top: 0;
  margin-bottom: $ss-40;
}

.addAnotherButton {
  color: $primaryDark;
  background-color: $neutralXLight;
  font-weight: 400;
  margin-top: $ss-6;
  &:hover {
    background-color: $neutralXLight;
  }

  &:not(:global(.compact)) {
    margin-left: $ss-20;
  }
  :global(.MuiButton-startIcon) {
    padding-bottom: $ss-2;
  }
}

.hide {
  display: none;
}

.error {
  :global(.MuiFilledInput-underline:after) {
    border-bottom: 2px solid $red;
  }
  :global(.MuiFormHelperText-root.Mui-error) {
    padding-top: $ss-16;
  }
}
.noSwap {
  :global(.MuiFormHelperText-root.Mui-error) {
    padding-top: 0;
    margin-bottom: 0;
  }
}

.flexContainer {
  display: flex;
  padding-left: $ss-16;
  padding-right: $ss-16;
}

.flexChild {
  flex: 1;
}

.inputField {
  width: 100%;
}

.trash {
  margin-left: $ss-8;
}

.input {
  :global(.MuiInputBase-root) {
    background-color: $white;
    border: 1px solid $neutral !important;
  }
}
