@import 'styles/_theme.scss';

.orgPlaceholderContainer {
  padding: $ts-24 $ts-40 0;
  letter-spacing: 0em;
  color: $neutralDark;
  opacity: 1;
}

.menu {
  & :global(.MuiMenu-paper) {
    box-shadow: 0px 3px 6px #00000029;
  }
}

.toolbar {
  background-color: $neutralXXLight;
  height: 4.75em;
}

.menuItem {
  color: $neutralDark;
  & :global(.MuiListItemIcon-root),
  & :global(.MuiListItemText-primary) {
    opacity: 1;
    font-size: $ts-16;
    letter-spacing: 0em;
  }
  & :global(.MuiListItemIcon-root) {
    min-width: 36px;
  }
}

.deactivate {
  color: $red;
}

.activate {
  color: $primary;
}
