@import 'styles/_theme.scss';

.itemGrid {  
  & legend{
    margin-bottom: $ss-14;
  }
}

.itemGridRowFieldPopulated {
  & :global(.MuiOutlinedInput-root) {
    background-color: $neutralXLight;
  }
}

.rowField {
  &:not(:last-child) {
    margin-right: $ss-24;
    margin-bottom: $ss-20;
  }

  & :global(.MuiAutocomplete-popper),
  & :global(.MuiAutocomplete-paper) {
    background-color: $white;
    & li {
      color: $neutralDark;
      &:hover {
        background-color: $neutralLight;
      }
    }
  }

  & :global(.MuiChip-root) {
    font-size: $ts-16;
    background-color: $neutralMed;
    color: $neutralXLight;

    & :global(.MuiSvgIcon-root) {
      color: $neutralXLight;
      font-size: $ts-16 !important;
      height: $ss-20;
      width: $ss-20;
    }
  }

  & :global(.MuiTextField-root) {
    background-color: $neutralXLight;

    & :global(.MuiInputBase-root) {
      color: $neutralDark;
    }

    & :global(.MuiOutlinedInput-root) fieldset {
      border-color: $neutralDark;     
    }

    & :global(.MuiAutocomplete-endAdornment) {
      top: -2px;
      right: 0;
    }

    & :global(.MuiIconButton-root) {
      width: $px-44;
      height: $px-44;

      & :global(.MuiSvgIcon-root) {
        color: $neutral;
      }

      &:hover {
        background-color: $neutralXLight;
      }
    }
  }
}

.warningBanner {
  background-color: $yellowLight;
  padding: $ss-4;
  margin-bottom: $ss-20; 
  border-radius: $ss-8;
  display: flex; 
}

.warningTextTypography {
  color: $neutralDark;
  margin: $ss-10 $ss-24;
  font-size: $ts-16;
}

.icon{
  margin-left: $ss-10;
  position: relative;
  top: $ss-10;
}

.message {
  color: $neutral;
  margin: $ss-10 $ss-24;
  font-size: $ts-16;
}

.submitButton { 
  margin-left: $ss-10;
}

.sectionTitle{
  font-size: $ts-22;
  color: $neutralDark;
  padding-top: $ss-10;
  padding-bottom: $ss-10;
  font-family: 'Roboto Slab';
}

.sectionDescription{
  font-size: $ts-16;
  color: $neutral;
  padding-bottom: $ss-10;
 
}

.divider{
  padding-top: $ss-30;
  margin-bottom: $ss-20;
}
.buttonContainer{
  float:right;
}
.panel{ 
  padding: $ss-40;
  }

.container{
 width: 80%;
 margin: 0px auto;
}

