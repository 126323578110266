@import 'styles/_theme.scss';

.container { 
  .page:hover{
    background-color: $white;
    border-radius: $ss-8;  
  }  
}

.margin{
    margin: 0 0 $ss-30 $ss-10;  
}

.page {
  font-size: $ts-16;
  text-align: left;
  color: $primary;
  opacity: 1;
  text-decoration: none;
  padding: $ss-10;
  margin: -$ss-10;
 
  & svg {
    vertical-align: middle;
    margin-top: -$ss-2;
  }

  & span {
    padding-left: $ss-12;
  } 
}
