@import 'styles/_theme.scss';

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkbox {
  &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: $primaryDark !important;
  }
  &:hover {
    background-color: $neutralXLight;
  }
}

.checkboxLabel {
  color: $neutralDark;
  font-size: $ts-16;
}
