@import 'styles/_theme.scss';

.cardHeader {
  color: $primaryDark;
  padding-top: $ss-16;
}

:global(#createUserFormUserCard) {
  margin-top: -$ss-8;
  border: 1px solid $neutralLight;
  border-radius: $ss-8;
  box-shadow: none;
}

.submitFormButtonContainer {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
}

.submitFormButton {
  background-color: $primary;
  color: $white !important;
  text-transform: none;
  box-shadow: none;
  border-radius: $ss-8;

  &:hover {
    background-color: $primary;
    box-shadow: none;
  }

  &.disabled {
    background-color: $primaryDisabled;
    &:hover {
      background-color: $primaryDisabled;
    }
  }
}
