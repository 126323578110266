@import 'styles/_theme.scss';

.cardContent {
  background-color: $white;
  padding: 0 0 0 $ss-36;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  &:global(.MuiCardContent-root:last-child) {
    padding-bottom: 0;
  }

  .cardContentItem {
    width: 100%;
    display: flex;
    align-items: center;

    $ratio: calc($ss-16 / $ss-12);

    .rowTitle {
      display: inline-block;
      min-width: $ss-94 * $ratio;
      max-width: $ss-94 * $ratio;
      margin-right: $ss-26 * $ratio;
      font-size: $ts-14;
      color: $neutral;
      opacity: 1;
      text-align: right;
      white-space: nowrap;
      align-self: baseline;
    }

    .data {
      font-size: $ts-16;
      color: $neutral;
      opacity: 1;
      align-self: baseline;
    }
  }

  .itemGap {
    margin-top: $ss-10;
    margin-bottom: $ss-24;
  }
}

.card {
  margin: $ss-20 0;
  border: 1px solid $neutralLight;
  border-radius: 8px;
  box-shadow: 0px 3px 6px $lightShadow !important;
}

.title {
  color: $neutralDark;
  font-size: $ts-22;
  margin-right: $ss-8;
  display: block;
  font-weight: 700;
  margin-top: $ss-16;
  font-family: 'Roboto Slab';
}

.wrapper {
  padding: $ss-16;
  padding-left: 0;
  width: 30em;
}

.header {
  font-weight: 700;
  font-size: $ss-22;
  padding-right: $ss-4;
}

.name {
  font-weight: 400;
  font-size: $ss-20;
  padding-right: $ss-4;
}

.status {
  color: $neutralDark;
  border-radius: $ss-14;
  font-size: $ts-12;
  padding: $ss-6 $ss-18;
}
.active {
  background-color: #00a88733;
}
.inactive {
  background-color: #f4f4f4;
}

.slash {
  margin-left: $ts-12;
  margin-right: $ts-12;
  color: $neutral;
}

.divider {
  width: 98%;
  border: 1px solid #0000001a;
  color: $neutral;
  margin: 0 0 $ss-24 0;
}

.editBtn {
  padding: $ss-8 $ss-8;

  &:hover {
    background-color: $neutralXLight;
  }
}
