@import 'styles/_theme.scss';

.card {
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid $neutralXLight;
  &:last-child {
    border-bottom: 0;
  }
}

.cardModule {
  display: flex;
  height: 64px;
  padding-left: 16px;
}

.moduleCheckboxLabel {
  color: $neutralDark;
  :global(.MuiTypography-root) {
    font-size: $ts-16;
    font-weight: 600;
  }
}

.moduleCheckBox {
  width: 48px;
  height: 48px;
  opacity: 1;
  padding: 0;
  margin-right: 1rem;
  &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: $primaryDark !important;
  }

  &:hover {
    background-color: $neutralXLight;
  }

  &:focus {
    background-color: $neutralXLight;
  }
}

.expand {
  &:hover {
    background-color: $neutralXLight;
  }

  &:focus {
    background-color: $neutralXLight;
  }
}

.cardActions {
  margin-left: auto;
}

.cardContent {
  padding: $ss-16 $ss-24;
  border-radius: $ss-8;
  background-color: $neutralXLight;
  margin-bottom: $ss-30;
}

.gridItemDividr {
  padding-top: $ss-4;
}

.appName {
  font-size: $ss-18;
}

.selectionContainer {
  margin-bottom: $ss-10;
}

.selectionName {
  padding: $ss-10 0 $ss-10 0;
  font-size: $ss-12;
}

.selectionOptions {
  margin-left: $ss-10;
}

.redoxInput {
  padding: $ss-14 0 $ss-10 0;
  margin-bottom: $ss-16;
  width: 97%;
}

.input {
  padding: 0 $ss-16;
  font-size: $ss-14;
  font-weight: 400;
  :global(.MuiInputBase-root) {
    background-color: $white;
  }
}

.identifier {
  color: $neutralDark;
  font-weight: 600;
  padding-left: 0px;
}
