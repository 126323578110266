@import 'styles/_theme.scss';

.createButton {
  float: right;
  margin: 16px 38px 0 0;
}

.gridItem {
  padding: $ss-16 $ss-10;
}

.dialogContent {
  padding: 0 $ss-24 $ss-8 $ss-24 !important;
  margin-top: $ss-18;
}

.dividr {
  margin: 0 !important;
}

.itemGridRowFieldPopulated {
  & :global(.MuiOutlinedInput-root) {
    background-color: $neutralXLight;
  }
}

.rowField {
  & :global(.MuiAutocomplete-popper),
  & :global(.MuiAutocomplete-paper) {
    background-color: $white;
    & li {
      color: $neutralDark;
      &:hover {
        background-color: $neutralLight;
      }
    }
  }

  & :global(.MuiChip-root) {
    font-size: $ts-16;
    background-color: $neutralMed;
    color: $neutralXLight;

    & :global(.MuiSvgIcon-root) {
      color: $neutralXLight;
      font-size: $ts-16 !important;
      height: $ss-20;
      width: $ss-20;
    }
  }

  & :global(.MuiTextField-root) {
    background-color: $white;

    & :global(.MuiInputBase-root) {
      color: $neutralDark;
    }

    & :global(.MuiOutlinedInput-root) fieldset {
      border-color: $neutralLight;
      color: $neutralDark;
    }

    & :global(.MuiAutocomplete-endAdornment) {
      top: -2px;
      right: 0;
    }

    & :global(.MuiIconButton-root) {
      width: $px-44;
      height: $px-44;

      & :global(.MuiSvgIcon-root) {
        color: $neutral;
      }

      &:hover {
        background-color: $neutralXLight;
      }
    }
  }
}

.closeButton {
  float: right;
}
