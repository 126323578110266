@import 'styles/_theme.scss';

.internalNotesPanelContainer{
    margin-top: $ss-50;
    border: 1px solid $neutralLight;
    border-radius: 8px;
    padding: $ss-14 $ss-24 $ss-24;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    box-shadow: 0px 3px 6px 0px #00000014;
}

.headingWrapper {
    display: flex;
    align-items: center;
    margin-bottom: $ss-22;
    & button {
        margin-left: 0;
    }
}
.internalNotesHeading {
    display: inline;
    margin: 0 $ss-4 0 0;
    font-size: $ts-22;
    font-weight: 400;
    color: $neutralDark;
    font-family: 'Roboto Slab';
}

.noteSkeletonContainer {
    padding: 10px 30px 50px;
}

.notesContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    & .noteCard:not(:last-child){
        border-bottom: 1px solid $neutralLight;
    }
}

.noteCard:first-of-type{
    border-top: 1px solid $neutralLight;
}

@media only screen and (max-width: 790px) {
    .notesContainer {
        grid-template-columns: 1fr;
    }
}
