/* main colors */
$primary: #0d8484;
$primaryLight: #aae2d0;
$primaryDark: #006272;
$accent: #dd372f;
$accentDark: #a03123;
$skylight: #00a2c7;
$stormy: #003a47;

/* neutrals colors */
$dark: #262626;
$neutralDark: #424242;
$neutralMed: #676767;
$neutral: #727272;
$neutralLight: #d8d8d8;
$neutralXLight: #f4f4f4;
$neutralXXLight: #f8f8f8;
$white: #ffffff;
$controlLight: #00000014;

/* accent colors */
$red: #dd372f;
$orange: #ff6c37;
$yellow: #ffb71b;
$green: #00856a;
$purple: #6c3175;

$redLight: #f8d7d5;
$orangeLight: #ffe1d6;
$yellowLight: #fff1d1;
$greenLight: #cceee7;
$purpleLight: #eee1f1;

/* misc */
$darkShadow: #00000026;
$lightShadow: #00000014;
$accentGreen: #00856a;
$primaryDisabled: #85c1c1;

$ts-4: 0.25rem;
$ts-8: 0.5rem;
$ts-12: 0.75rem;
$ts-14: 0.875rem;
$ts-16: 1rem;
$ts-18: 1.125rem;
$ts-20: 1.25rem;
$ts-22: 1.375rem;
$ts-24: 1.5rem;
$ts-25: 1.563rem;
$ts-26: 1.625rem;
$ts-30: 1.875rem;
$ts-32: 2rem;
$ts-36: 2.25rem;
$ts-38: 2.375rem;
$ts-40: 2.5rem;
$ts-48: 3rem;
$ts-54: 3.375rem;
$ts-64: 4rem;

$ss-2: 0.125em;
$ss-3: 0.188em;
$ss-4: 0.25em;
$ss-5: 0.3125em;
$ss-6: 0.375em;
$ss-8: 0.5em;
$ss-10: 0.625em;
$ss-12: 0.75em;
$ss-11: 0.688em;
$ss-14: 0.875em;
$ss-15: 0.938em;
$ss-16: 1em;
$ss-18: 1.125em;
$ss-22: 1.375em;
$ss-20: 1.25em;
$ss-24: 1.5em;
$ss-25: 1.5625em;
$ss-26: 1.625em;
$ss-28: 1.75em;
$ss-30: 1.875em;
$ss-32: 2em;
$ss-34: 2.125em;
$ss-35: 2.188em;
$ss-36: 2.25em;
$ss-40: 2.5em;
$ss-42: 2.625em;
$ss-44: 2.75em;
$ss-45: 2.813em;
$ss-48: 3em;
$ss-50: 3.125em;
$ss-56: 3.5em;
$ss-58: 3.625em;
$ss-64: 4em;
$ss-80: 5em;
$ss-94: 5.875em;

$hs-1: $ts-36;
$hs-2: $ts-24;
$hs-3: $ts-18;
$hs-4: $ts-16;
$hs-5: $ts-14;
$hs-6: $ts-12;

$px-24: 24px;
$px-44: 44px;
