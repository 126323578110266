@import 'styles/_theme.scss';

.container {
    margin-top: $ss-22;
}

.title {
    font-size: $ts-18;
    color: $primaryDark;
    opacity: 1;
    padding-bottom: $ss-8;
}
  
.subTitle {
    font-size: $ts-16;
    color: $neutral;
    opacity: 1;
    padding-bottom: $ss-20;
}

.notesContainer{
    display: flex;
    flex-direction: column;
    & .noteCard:not(:last-child){
        border-bottom: 1px solid $neutralLight;
    }
}

.noteCard {
    & :global(.MuiCard-root){
        background-color: $neutralXXLight;
    }
}

.noteCard:first-of-type{
    border-top: 1px solid $neutralLight;
}

@media only screen and (max-width: 790px) {
    .notesContainer {
        grid-template-columns: 1fr;
    }
}
