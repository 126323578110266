@import 'styles/_theme.scss';

.restPasswordButton {
  color: $neutralDark;
  border-radius: $ss-8;
  border: 1px solid $neutralDark;
  text-transform: none; 
  font-size: $ts-16;
  font-weight: 400;
  line-height: $ts-16;
  padding: 9px $ss-18;
 
  &:hover {
    background-color: $white;   
  }
}
