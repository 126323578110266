@import 'styles/_theme.scss';

.cardHeadline {
  font-size: $ts-22;
  color: $neutralDark;
  height: $ts-24;
  margin: 0px;
  font-family: 'Roboto Slab';
}

.cardDescription {
  border: 1px solid transparent;
  color: $neutral;
  margin: 0px;
  padding: $ss-6 0 $ss-24 0;
  max-width: 58ch;
  word-wrap: break-word;
}

.empty {
  padding-top: $ss-24;
  margin: 0px;
}
