@import 'styles/_theme.scss';

.data {
  color: $neutralDark;
}

.buttonDivider {
  width: $ss-10;
  height: auto;
  display: inline-block;
}

.statusHeaderItem {
  padding: 0 $ts-14 0 0;
}

.detailItem {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
}

.userDetailContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $ss-24;
  border: 1px solid $neutralXLight;
  border-radius: 8px;
  box-shadow: 0px 3px 6px $darkShadow;
  margin-top: -$ss-8;
}

.userDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.userName {
  font-size: $ts-20;
  color: $neutralDark;
  font-weight: 500;
}

.userEmail {
  color: $neutralMed;
  margin-top: $ss-20;
  font-size: $ts-14;
}

.startAndEndDate {
  margin-top: $ss-14;
}

.startDateSpan {
  margin-right: $ss-14;
  font-size: $ts-14;
  color: $neutralMed;
}

.endDateSpan {
  margin-right: $ss-16;
  font-size: $ts-14;
  color: $neutralMed;
}

.loginActivity {
  font-size: $ts-14;
  color: $neutralMed;
  margin-top: $ss-24;
}

.editButton {
  position: relative;
  bottom: 4px;
  color: $neutralDark;
  padding: 8px;
  margin-left: 12px;
}

.cancelButtonSmall {
  color: $neutralMed;
  font-size: $ts-16;
  padding: $ss-4 $ss-18;
  margin-left: $ss-4;
}

.editUserContainer {
  padding: 0%;
}

.editUserBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $ss-20;
}

.roleAssignment {
  & :global(.MuiCardContent-root) :global(.MuiCardHeader-root) {
    padding-left: 0;
  }
}

.submitFormButtonContainer {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  top: -80px;
  position: relative;
}

.cardDetails {
  width: 50%;
}

.userDetails {
  width: 50%;
}

.userBtns {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.userSection {
  display: flex;
}
.rolesSection {
  display: flex;
  padding: $ss-24 $ss-24 0 $ss-24;
}

.roleBtns {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.resetPassword {
  padding-top: $ss-24;
}

.userActionMenu {
  padding-top: $ss-40;
}

.editBtn {
  display: flex;
  justify-content: flex-end;
}

.nameSection {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.itemGap {
  padding: $ss-12;
}

.lastLoginGap {
  padding-left: 26px;
}

.startDateGap {
  padding-left: 28px;
}

.endDateGap {
  padding-left: 34px;
}

.emailGap {
  padding-left: 56px;
}
