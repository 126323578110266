@import 'styles/_theme.scss';

.addNoteTextField{ 
    :global(.MuiInputBase-root) {
        background-color: $neutralXLight;
        color: $neutralDark;
        border-radius: $ss-8;
        border: 1px solid $neutral;
        padding: $ss-10 $ss-18;
        &:hover{
            background-color: $neutralXLight;
        }
        & textarea {
            border-radius: $ss-4;
            &::placeholder{
                color: $neutralDark;
                opacity: 1;
            }
        }
    } 
    :global(.MuiFilledInput-underline:before) {
        border-bottom: none !important;
    }
    :global(.MuiFilledInput-underline:after) {
    border-bottom: none !important;
    }
    :global(.MuiFilledInput-root.Mui-error){
        border: 1px solid $neutral;
    }
    & :global(.MuiInputBase-root):focus-within:hover{
        background-color: $neutralXLight;
    }
}

.tenantAddNoteTextField{
    :global(.MuiInputBase-root) {
        background-color: $white;
        &:hover{
            background-color: $white;
        }
    }
    & :global(.MuiInputBase-root):focus-within:hover{
        background-color: $white;
    }
}

.addNoteFooter{
    display: flex;
    justify-content: flex-end;
    margin: $ss-16 0;
}

.orgNotesform{
    grid-column: 1 / 2; 
}

.tenantNoteFooter {
    & .cancelBtn {
        background-color: $neutralXXLight;
        color: $primaryDark;
    }
}

.cancelBtn {
    margin-right: $ss-12;
}