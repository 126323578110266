@import 'styles/_theme.scss';

.link{
    color: $primary;
    text-decoration-color: $primary;    
}

.current{
    color: $neutralDark;
}

