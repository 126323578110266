@import 'styles/_theme.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, -apple-system,
    BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  color: $neutral;
}
#root {
  height: 100%;
}
#main {
  &:focus {
    outline: none;
  }
}
//Override mui styles globally
.MuiTooltip-tooltip {
  font-size: $ts-14;
}

.MuiTab-root.Mui-selected {
  font-weight: bold;
  color: $dark;
}

.MuiTableCell-paddingCheckbox:last-child {
  padding-right: $ss-36;
}

.MuiTypography-caption {
  color: $neutralDark;
  font-size: $ts-14;
}

.MuiInputBase-root::after {
  border-bottom: none;
}

.MuiInputBase-root::before {
  border-bottom: none !important;
}
.MuiDialogTitle-root {
  .MuiTypography-h6 {
    font-size: $ts-25;
    color: $primaryDark;
  }
}

.MuiButton-root {
  &:focus {
    outline: 2px dotted $neutralDark;
    outline-offset: 2px;
    box-shadow: none;
  }
  .MuiTouchRipple-root {
    display: none;
  }
}

.MuiLink-root {
  &:focus {
    outline: 2px dotted $neutralDark;
    outline-offset: 2px;
    box-shadow: none;
  }
}

.MuiToggleButton-root {
  &:focus {
    outline: 2px dotted $neutralDark;
    outline-offset: 2px;
    box-shadow: none;
  }
  .MuiTouchRipple-root {
    display: none;
  }
}

.MuiTablePagination-toolbar {
  width: 98%;
  padding-right: 2%;
  background-color: $neutralXXLight;
  .MuiIconButton-root {
    color: $neutralDark;
    &:hover {
      background-color: $neutralLight;
    }
    &:disabled {
      color: #b5b5b5;
    }
  }
  .MuiTypography-caption {
    padding: 0 $ss-10 0 $ss-10;
  }
  .MuiTablePagination-select {
    background-image: url('/assets/icons/keyboard_arrow_down.svg');
    background-repeat: no-repeat;
    background-position: right 10% bottom 50%;
  }
}
.MuiSelect-select.MuiSelect-select {
  background-color: $white;
  border-radius: $ss-8;
  padding: $ss-11 $ss-32 $ss-11 $ss-18;
  color: $neutralDark;
}

.MuiSelect-icon {
  display: none;
}

.MuiIconButton-root {
  color: $neutralDark;
}

.MuiTableSortLabel-root {
  &:focus {
    outline: 2px dotted $neutralDark;
    outline-offset: 2px;
    box-shadow: none;
  }
}

.MuiTablePagination-displayedRows {
  display: none;
}

.MuiTablePagination-select {
  border: 1px solid #727272;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  &:focus{
    outline: 2px dotted $neutralDark;    
  }
  &:focus-within {   
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.MuiTablePagination-selectLabel {
  display: inline !important;
}

.MuiIconButton-root {
  &:focus {
    outline: 2px dotted $neutralDark;
  }
}

.react-list-editable .input-field {
  width: 500px !important;
}

.MuiInputBase-root {
  &:focus-within {
    outline-offset: 2px;
    outline: 2px dotted $neutralDark;
  }

  &:focus {
    background-color: $neutralLight;         
  }
}

.MuiAutocomplete-root .MuiFilledInput-root {
  padding-top: 0;
}

.MuiBreadcrumbs-root .MuiBreadcrumbs-separator {
  color: $neutralDark;
}

.MuiPaper-root{
  box-shadow: 0px 3px 6px $darkShadow;
}

.MuiTableCell-root{
  border-bottom: 1px solid $neutralXLight;
}
