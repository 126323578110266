@import 'styles/_theme.scss';

.footer {
  bottom: 0;
  left: 0;
  right: 0;
  background: $neutralDark;
  height: auto;
  padding-left: $ss-24;
}

.copyright {
  color: $white;
  font-size: $ts-14;
  font-weight: normal;
  text-decoration: none;
  white-space: pre-wrap;
  display: inline-flex;
  flex-direction: row;
  margin: $ss-14 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.terms {
  color: $white;
  text-decoration: underline;
  white-space: nowrap;
  display: inline-flex;
  padding: 0 8px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  &:global(.MuiTypography-root):focus-visible {
    outline: none;
    border: 2px dashed $white;
  }
}

@media only screen and (max-width: 700px) {
  .copyright {
    width: 100%;
    margin: $ss-8;
  }
}

.bullet {
  margin: 0px;
}
