@import "styles/_theme.scss";

.cardHeadline {
  color: $neutralDark;
  height: $ts-24;
  margin-top: 0;
  margin-bottom: $ss-30;
}

.subscriptionSelector {
  padding-top: 0;
}