@import 'styles/_theme.scss';

.labelContainer{
    display: flow-root;  
    margin: 0 $ss-24 $ss-10 $ss-24;
    padding-top: $ss-12;
}

.labelContainer .switch{
   float: right;  
   margin-top: $ss-10;         
}
.labelContainer .switchLabel{
    float: left;
    color: $neutralDark;
    font-weight: 600;      
}

.description{  
    color: $neutral;
    font-size: $ts-16;
    font-weight: 400;
}
