@import 'styles/_theme.scss';

.cardContent {
  background-color: $white; 
  padding: 8px 24px 6px 24px !important;
  &:hover {
    background: #0D84840A;   
    border-radius: $ss-8;  
  }
 
}

.ul {
  list-style: none;
  margin: 0em;
  padding: 0em;
  background-color: $white;
}

.card { 
  border-bottom: 1px solid $neutralXLight;    
  box-shadow: none !important;
  height: 72px;

  &:hover { 
   border-bottom:  1px solid transparent; 
  }
  & :global(.MuiCardContent-root):last-child {
    padding: 0;
  }
 
  & :global(.MuiCardContent-root) {
    padding-bottom: 1rem;
  }
  
}
