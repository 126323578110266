@import 'styles/_theme.scss';

.createUser {
  text-transform: capitalize;
  font-size: $ts-16;
  float: right;
  z-index: 1000;
  background-color: $primary;
  color: $white;
  box-shadow: none;
  margin: $ss-14 $ss-16 0 $ss-20;
  border-radius: $ss-8;
  line-height: $ss-20;
  height: $ss-42;
  padding: $ss-8 $ss-18;
  &:hover {
    background-color: $primary;
    box-shadow: none;
  }
}


.greyMenuItem {
  color: $neutralDark;
  p {
    padding-left: $ss-10;
  }
}

.toolbar {
  background-color: $neutralXXLight;
  height: 76px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: '426.76px';

  & :global(.MuiInputAdornment-root.MuiInputAdornment-positionEnd) {
    visibility: hidden;
  }
}

.menu {
  & :global(.MuiMenu-paper) {
    box-shadow: 0px $ss-3 $ss-6 #00000029;
  }
}

.btnText {
  padding-left: $ss-8;
  white-space: nowrap;
}

.footer {
  margin-top: $ss-14;
  font-style: normal;
  font-size: $ss-16;
}

.table {
  border-radius: $ss-8 $ss-8 0px $ss-8;
}
