@import 'styles/_theme.scss';

.header {
  font-weight: bold;
}

.data {
  color: $neutralDark;
}

.modalButton {
  background: $white;
  border: 1px solid $primary;
  color: $primary;
  float: right;
  margin-left: auto;
  border-style: solid;
  align-self: center;
  &:hover {
    background: $white;
  }
}

.gridItem {
  padding: 0 $ss-10 0 $ss-10;
}

.gridItemSalesForce {
  padding: 0 $ss-10 $ss-10 $ss-10;
}

.radioGroup {
  padding: $ss-10 0 0 $ss-10;
}

.inputAdornment {
  padding: 2px 0 0 0;
}

.textField {
  :global(.MuiInputBase-root) {
    height: $ss-42;   
    padding: $ss-3 $ss-3;
    border-radius: 4px;
    border: 1px solid $neutral;
    &:hover {
      transform: none;
    }
  }

  :global(.MuiFilledInput-root) {
    background-color: $neutralXLight;

    &:hover {
      transform: none;
      background-color: $neutralXLight;
    }
    &:focus-within {
      outline-offset: 2px;
      outline: 2px dotted $dark;
    }
  }
 
}

.closeButton {
  float: right;
}
