@import 'styles/_theme.scss';

.fieldRow {
  & :global(.MuiGrid-root):not(:last-child) {
    margin-right: $ss-24;
  }

  &:not(:last-child) {
    margin-bottom: $ss-16;
  }

  &:last-child .userField {
    height: unset;
    min-height: $ss-56;
  }
}

.textInput {
  width: 356px !important;
}

.lifespanFields {
  padding-bottom: $ss-16;
}
