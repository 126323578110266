@import 'styles/_theme.scss';

.overrideActions {
  display: block;
  text-align: right;
  padding: $ss-8 $ss-8 $ss-8 0;
}

.errorMessage {
  color: $red;
  font-size: $ts-14;
  display: block;
  margin-bottom: $ss-12;
}

.divider {
  background-color: $neutralLight;
  margin-bottom: $ss-30;
  margin-top: $ss-30;
}

.sectionTitle {
  padding-bottom: $ss-8;
  text-align: left;
  font-size: $ts-22;
  letter-spacing: 0em;
  color: $neutralDark;
  opacity: 1;
  font-family: 'Roboto Slab';
  margin-left: 10px;
}

.subSectionText {
  color: $neutral;
  margin: 0px;
  opacity: 1;
  white-space: pre-wrap;
  line-height: 1.7;
  font-size: $ts-16;
  padding-bottom: $ss-16;
  margin-left: 10px;
}

.divider {
  background-color: $neutralLight;
  margin: $ss-40 0 $ss-40 0;
}
.subscriptionTitle {
  color: $neutralDark;
  font-size: $ts-20;
  font-weight: 700;
  padding-bottom: $ss-24;
}

.subName {
  font-weight: 400;
  padding-left: $ss-10;
}

.settingContainer {
  background-color: $neutralXLight;
  padding: $ss-10 0 $ss-10 0;
  border-radius: $ss-8;
}
