@import 'styles/_theme.scss';

.userCard {
    border: 1px solid $neutralLight;
    border-radius: 8px;
    box-shadow: 0 3px 6px $neutralLight;
    margin-bottom: $ss-16;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.cardHeaderContainer {
    background: $white;
    display: flex;
    align-items: center;
    padding: $ss-24;
}

.cardHeader{
    font-size: $ts-20;
    font-weight: 500;
    color: $neutralDark;
    margin: 0;
}

.cardSubHeader{
    font-size: $ts-16;
    color: $neutral;
}

.btnContainer{
    margin-right: $ss-16;
    margin-left: auto;
}

.btnWrapper {
    display: inline;
    margin-left: $ss-8;
}

.cardContentContainer {
    padding: $ss-16 1.5em $ss-16 4.5em;
    color: $neutralDark;
}


