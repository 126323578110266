@import 'styles/_theme.scss';

.btnWrapper {
  display: inline;
  margin-left: $ss-8;
}

.wrapper {
  padding: 0 $ss-16;
  padding-left: 0;
  width: 30em;
}

.textField {
  height: $ss-48;
  :global(.MuiInputBase-root) {
    background-color: $neutralXLight;
  }
}

.btnContainer {
  margin-right: $ss-16;
  margin-left: auto;
  margin-top: $ss-12;
}

.header {
  font-weight: 700;
  font-size: $ss-20;
  padding-right: $ss-16;
  //padding-top: $ss-8;
}
