@import 'styles/_theme.scss';

.link {
  &:hover {
    text-decoration: none;
    background-color: $white;
  }
}

// All buttons should have these common styles
// Include with any button. By doing so, custom styling should be minimal. (mainly colors)
.button {
  padding: 9px $ss-18;
  border-radius: $ss-8;
  height: $ss-44;
  font-size: $ts-16;
  line-height: $ss-20;
  text-transform: none;
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
}

.primaryButton {
  background-color: $primary;
  color: $white;
  &:hover {
    background-color: $primary;
  }
  &:disabled,
  &:global(.Mui-disabled) {
    background-color: $primaryDisabled;
    color: $white;
    opacity: 1;
  }
}

.secondaryButton {
  border: 1px solid $primary;
  color: $primary;
  background-color: $white;
  &:hover {
    background-color: $white;
    border-color: $primary;
  }
}

.secondaryDarkButton {
  border: 1px solid $neutralDark;
  color: $neutralDark;
  background-color: $white;
  &:hover {
    background-color: $white;
    border-color: $neutralDark;
  }
}

.destructivePrimary {
  background-color: $red;
  color: $white;
  &:disabled {
    opacity: 0.6;
    color: $white;
  }
  &:hover {
    background-color: $red;
  }
}

.destructiveSecondary {
  border: 1px solid $red;
  color: $red;
  background-color: $white;
  &:hover {
    background-color: $white;
    border-color: $red;
  }
}

.textButton {
  background-color: white;
  color: $primary;
  border: none;
  &:hover {
    background-color: $white;
  }
  :global(.MuiButton-startIcon) {
    padding-bottom: $ss-2;
  }
}

.expandButton {
  color: $neutralDark;
}

.generalCancelButton {
  border: 1px solid $neutralLight;
  color: $neutral;
  background-color: $white;

  &:hover {
    background-color: $white;
    border-color: $neutralLight;
  }
}

.confirmationModalButton {
  color: $white;
  margin-left: $ss-8;
}

.backgroundGreen {
  background-color: $primary;
  border: 1px solid $primary;

  &:hover {
    text-decoration: none;
    background-color: $primary;
    border-color: $primary;
  }
}

.backgroundRed {
  background-color: $red;
  border: 1px solid $red;

  &:hover {
    text-decoration: none;
    background-color: $red;
    border-color: $red;
  }
}

.disabled {
  background-color: $primaryDisabled;
  color: $white !important;
}
