@import 'styles/_theme.scss';

.rolesLegend {
    font-size: $ts-12;
    margin-bottom: $ss-28;
}

.noRoles {
    color: $neutralLight;
    height: 100%;
}

.checkboxLabel{
    color: $neutralDark;
    display: flex;
    height: $ss-28;
}


.checkbox {
    &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: $primaryDark !important;
    }
}

.description{
    font-size: $ts-14;
    color: $neutral;
    padding-left: $ss-36;
    padding-bottom: $ss-10;
}