@import 'styles/_theme.scss';

.filterContainer {
  align-items: center;
  background-color: $white;
  padding-bottom: $ss-20;
  display: flex;
 
}

.filterBy{
  min-width: 45%;
  align-items: center;
  display: flex;
}

.filterLabel{
  color: $neutral;
  font-size: $ts-16;
  font-weight: 500;
}

.orgCategoryButton { 
  padding: $ss-6 $ss-16;
  border-radius: $ss-8 !important; 
  background-color: $white;
  color: $neutralDark;
  font-size: $ts-16;
  font-weight: 500; 
  height: $ss-32;
  text-transform: capitalize;
  &:global(.MuiToggleButton-root.Mui-selected) {
    color: $neutralDark;
    background-color: #AAE2D082 ;
    font-weight: 500;
    border: 1px solid #AAE2D082;  
  
  }
  &:hover {
    background-color: $white;
  }
 
}
.check{
  padding-right: $ss-6;
}

.searchField{ 
  padding-right: $ss-10;  
 
  :global(.MuiInputBase-root) {
    height: $ss-44;     
    border-radius: $ss-8;    
    &:hover {
      transform: none;
    }
  }
  :global(.MuiFilledInput-input) {
    padding: 0 $ss-10 0 0;
  }

  :global(.MuiFilledInput-root) {
    background-color: $neutralXLight;   
    &:hover {
      transform: none;
      background-color: $neutralXLight;
    }
    &:focus-within {
      outline-offset: $ss-2;
      outline: $ss-2 dotted $dark;
    }
  }
}

.inputAdornment{
  padding-right: $ss-10;
}



.searchContainer{  
  min-width: 305px;
  @media screen and (max-width: 1024px) {
    margin-left: 5%;    
    margin-right: 5%;
    min-width: 180px;  
  }
  @media screen and (max-width: 768px) {
    margin-left: 5%;       
  }
}

