@import 'styles/_theme.scss';

.addApplicationSection {
    padding: $ss-16 0 0 0;
}

.columnFirst{
    margin-right: $ss-12;
}
.columnLabel{
   
    font-size: $ts-14;
    color: $neutralDark;
    padding-bottom: $ss-20;
}

