@import 'styles/_theme.scss';

.statusChip {
  width: auto;
  font-size: $ts-12;
  margin-right: $ss-12;
  background-color: $greenLight;
  color: $neutralDark; 
  height: 22px;
  text-transform: uppercase;

  & :global(.MuiChip-label) {
    line-height: normal;
  }

  &:global(.active) {
    background-color: $greenLight;
  }

  &:global(.staged), &:global(.resendinvite) {
    background-color: $yellowLight;
  }

  &:global(.suspended), &:global(.inactive) {
    background-color: $neutralXLight;
  }

  &:global(.provisioned), &:global(.invitesent) {
    background-color: rgba(0, 162, 199, 0.20);
  }

  &:global(.deprovisioned), &:global(.removed) {
    background-color: #{$red}33;
  }

  &:global(.invitepending) {
    background-color: $redLight;
  }
}