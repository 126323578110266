@import 'styles/_theme.scss';

.noteCard {
    &:global(.MuiCard-root){
        border-radius: 0;
        box-shadow: none;
    }
}

.tenantCard {
    & .textBtn {
        background-color: $neutralXXLight;
        color: $primaryDark;
    }
    .deleteBtn button:hover {
        background-color: $white;
    }
    & .editBtn {
        color: $neutralDark;
        &:hover {
            background-color: $white;
        }
    }
}

.editBtn:hover {
    background-color: $neutralXLight;
    
}

.deleteBtn button:hover {
    background-color: $neutralXLight;
}

.cardHeader {
    padding: $ss-32 $ss-8 $ss-8 0;
    & .name {
        font-size: $ts-16;
        font-weight: 600;
        margin-bottom: 5px;
        color: $neutralDark;
    }
    & .dateTime, .slash{
        color: $neutral;
        font-size: $ts-14;
        line-height: $ts-24;
    }
    & .slash {
        margin: 0 $ss-10;
    }
}

.cardContent {
    padding: 0 0 $ss-16 0 !important;
}

.noteText {
    font-size: $ts-16;
    color: $neutralDark;
    margin: 0;
    line-height: $ts-25;
    white-space: pre-wrap;
}

.textBtn {
    display: block;
    border: none;
    background-color: $white;
    text-decoration: underline;
    color: $primary;
    font-size: $ts-16;
    line-height: $ts-25;
    padding: 0;
    cursor: pointer;
}

.cutOff{
    height: 125px;
    width: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.showAll{
    display: inline-block;
    overflow: visible;
    height: auto;
}