@import 'styles/_theme.scss';

.salesforceContainer{ 
    border-radius: $ss-8;
    box-shadow: 0px 3px 6px 0px $darkShadow;
    margin-bottom: $ss-50;
    margin-top: $ss-30;
    border: 1px solid $neutralLight;  
}

.dataContent{
    margin-bottom: 2em;
}

.cardContent {
    padding: $ss-24 $ss-24 $ss-24 $ss-36;
}

.headerContainer{
    border-radius: $ss-8;
    display: flex;
    align-items: center;
    background-color: $white;
    margin: 0;
    padding: $ss-6 $ss-36;
}

.salesforceHeader{
    color: $neutralDark;
    font-size: $ts-22;  
    font-weight: 400;
    display: inline;     
    font-family: 'Roboto Slab';
}

.data{
    color: $neutralDark;
    margin: 0;
}

.header{
    font-size: $ts-14;
    color: $neutral;
}