@import 'styles/_theme.scss';

.container { 
  background-color: $neutralXXLight;
  height: 116px;    
}

.inner{
  padding-top: $ss-30;
  padding-left: $ss-30;
  max-width: 1400px;
  margin-left: auto; 
  margin-right: auto;   
}


.title {
  color: $dark;
  font-size: $ts-26;
  margin: 0;
  display: block;
  font-weight: 400;
  gap: $ss-10;
  font-family: 'Roboto Slab';
  padding-bottom: $ss-4; 
}
.secondary {
  color: $neutral; 
  display: block;
  font-size: $ts-16;
}
.center{
  padding-top: 10px;
}

.gap {
  padding-bottom: $ss-32;
}
