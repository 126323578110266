@import 'styles/_theme.scss';

.container {
  margin: 0 0 $ss-30 $ss-10;  
}

.backToPrevPage {
  font-size: $ts-16;
  text-align: left;
  color: $primaryDark;
  opacity: 1;
  text-decoration: none;
  padding: $ss-10;
  margin: -$ss-10;
 
  & svg {
    vertical-align: middle;
    margin-top: -$ss-2;
  }

  & span {
    padding-left: $ss-12;
  }
 }
