@import 'styles/_theme.scss';

.appBar {
  background: $primaryDark;
  color: $white;
  box-shadow: none;
  height: 72px;
  flex: 0 1 auto;
  position: sticky;
  top:0;
  z-index: 1100;  
  border-bottom: 2px solid $primaryLight;  
 
} 
.skipLink{
  position: absolute;
  outline: 1px solid $white;
  color: $primaryDark;
  font-size: $ts-12;
  padding: $ss-16;
  &:focus {
    position: relative;
  }
}
.logo {
  height: 68px;
  width: 387px;
}
.logoWrapper{ 
  &:global(.MuiLink-root):focus{   
    outline: 2px dashed $white;
    outline-offset: -4px;
  }
}

.gridContainer{
  padding-right: $ss-20;
  padding-left: $ss-20; 
  max-width: 1440px;
  margin-left: auto; 
  margin-right: auto;
}

.icon { 
  padding-right: $ss-6; 
}

.appName{
  font-size: $ts-25;
}
.tool {
  color: $white;
  font-size: $ts-12;
  font-style: italic;
  padding-top: $ss-2;
}

.name {
  font-size: $ts-18;
}
.orgName{
  font-size: $ts-18;
}

.divider{
  margin-left: $ss-24;
  margin-right: $ss-24;
  background-color: $white;
  height: $ss-44; 
}




@media only screen and (max-width: 906px) { 
  .toggle{
    display: none;
  }
}


@media only screen and (max-width: 750px) { 
  .appName {
    display: none;
  }
  .icon{
    display: none;
  } 
  .toggle{
    display: none;
  }
}

@media only screen and (max-width: 480px) { 
  .orgName {
    display: none;
  }
  .appName {
    display: none;
  }
  .icon{
    display: none;
  } 
  .toggle{
    display: none;
  }
}
