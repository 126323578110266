@import 'styles/_theme.scss';

.dialogContent {
  padding: 0 0 $ss-10 0;
  margin: 0;
}

.highlightedText {
  background-color: $yellowLight;
  padding: $ss-12 $ss-24;
  line-height: $ss-30;
  white-space: pre-wrap;
  color: $neutralDark;
}

.modalText {
  padding: 0 $ss-24;
  line-height: $ss-30;
  white-space: pre-wrap;
  color: $neutralDark;
  font-size: $ts-16;
}

.textFieldContainer {
  margin: 0 $ss-24 $ss-4 $ss-24;
}

.modalBtnsContainer {
  margin: $ss-4 $ss-20 $ss-24 0;
  gap: $ss-8;
}

.input {
  font-size: $ss-14;
  font-weight: 400;
  :global(.MuiInputBase-root) {
    background-color: $neutralXLight;
  }
}
