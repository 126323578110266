@import 'styles/_theme.scss';

.warningBanner {
  background-color: $yellowLight;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.warningTextTypography {
  color: $neutralDark;
  margin: $ss-10 $ss-24;
  font-size: $ts-16;
}

.title {
  padding: $ss-12 $ss-16 $ss-10 $ss-16; //computed values are larger with the larger font-size
  font-size: $ts-24;
  font-weight: 600;
  color: $primaryDark;
}

.dialogContentText {
  color: $neutralDark;
  font-size: $ts-16;
  padding-top: $ss-10;
  padding-bottom: $ss-12;
}

.rightAlignedBtnGroup {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin: 0 $ss-16 $ss-16 0;
  gap: $ss-8;
}
.alarm {
  background-color: red;
  height: 6px;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
}

.textField {
  & :global(.MuiFilledInput-root) {
    height: $ss-42;
    background-color: $neutralXLight;
    border-radius: $ss-8;
  }

  & :global(.MuiFilledInput-input) {
    padding-top: $ss-10;
    padding-bottom: $ss-10;
  }

  & :global(.MuiFilledInput-root:hover) {
    transform: none;
    background-color: $neutralLight;
  }
}
