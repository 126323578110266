@import 'styles/_theme.scss';

.orgNameText {
  font-size: $ts-16;
  color: #424242;
  opacity: 1;
  margin: 0;
}

.orgInformation {
  padding-left: $ss-25;

  display: flex;
  align-items: center;
  margin: 0;
}

.orgContainer {
  display: flex;
  justify-content: space-between;
  margin: 5px 25px 5px 5px;
}

.orgActions {
  align-self: center;
  margin: 0;
}
.chip {
  color: $neutralDark;
  border-radius: $ss-14;
  font-size: $ts-12;  
  margin-left: $ss-20; 
  padding: $ss-4 $ss-12;
}
