@import 'styles/_theme.scss';

.name {
  padding-top: $ss-20;
}

.input {
  font-size: $ss-14;
  font-weight: 400;
  :global(.MuiInputBase-root) {
    background-color: $neutralXLight;
  }
}
