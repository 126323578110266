@import 'styles/_theme.scss';

.inputLabel {
  font-size: $ts-14;
  margin-bottom: $ts-8;
  color: $neutral;
}

// Only way I could figure out how to implement the dashed line, no way to talk
// to the .Mui components that wrap the TextField.
.muiWrapper {
  margin: 0;
  box-shadow: none;
  padding: 0 0 0 0;
  flex-grow: 1;
  & > :global(.MuiInputLabel-root) {
    font-size: $ts-12;
    margin-bottom: $ts-8;
    color: $neutralDark;
  }
}

// TODO Cannot figure out how to apply the border-radius
.muiTextField {
  & input::placeholder {
    color: $neutralDark;
    opacity: 1;
  }
  & input:focus::placeholder {
    opacity: 0.5;
  }

  :global(.MuiFilledInput-underline:before) {
    border-bottom: none !important;
  }

  :global(.MuiFilledInput-underline:after) {
    border-bottom: none !important;
  }
  :global(.MuiFilledInput-underline:before) {
    border-bottom: none !important;
  }
  :global(.MuiFilledInput-root.Mui-error) {
    border: 1px solid $red;
  }

  :global(.MuiFormHelperText-root) {
    color: $neutralDark;
  }
  :global(.MuiFormHelperText-root.Mui-focused) {
    color: $dark;
  }
  :global(.MuiFormHelperText-root.Mui-error) {
    color: $red;   
    padding-left: $ss-36;
    margin-top: $ss-6;
    padding-bottom: $ss-6;
    margin-left: 0;
    background-image:  url('/assets/icons/warning.svg');
    background-repeat: no-repeat;
   
  }

  :global(.MuiInputLabel-root) {
    color: $neutralDark;
  }
  :global(.MuiInputLabel-root.Mui-focused) {
    color: $dark;
  }
  :global(.MuiInputLabel-root.Mui-error) {
    color: $red;
  }
  :global(.MuiInput-root.Mui-error:before) {
    border-bottom-color: transparent;
  }

  :global(.MuiFilledInput-root) {
    transform: none;
  }
  :global(.MuiFilledInput-root:focus) {
    background-color: $neutralLight;
  }
  :global(.MuiFilledInput-root:hover) {
    background-color: $neutralLight;
  }

  & :global(.Mui-disabled),
  & :disabled {
    opacity: 0.5;
  }
}

.baselineTextField {
  :global(.MuiInputBase-root) {
    background-color: $white;
    border-radius: 8px;
    padding-right: $ss-16;
    border: 1px solid $neutral;
  }
  :global(.MuiInputBase-input) {
    padding: $ss-10 0 $ss-10 $ss-16;
    border-radius: 8px;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $neutralLight inset;
    box-shadow: 0 0 0px 1000px $neutralLight inset;
  }

  :global(.MuiInputAdornment-positionStart) {
    margin-top: 0 !important;
  }
}
