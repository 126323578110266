@import 'styles/_theme.scss';

.gridContainer{
    padding-left: 20px;
    box-shadow: 0px 3px 6px 0px $controlLight;
    z-index: 1000;
   
}

.gridItem{
  max-width: 1420px;
  margin-left: auto; 
  margin-right: auto;
}

.tab {
    text-transform: capitalize;
    height: 60px;
    min-width: 60px;
    font-size: $ts-18;
    font-weight: 300;
    color: $dark;
    opacity: 1;    
    &:focus-visible {
      border: 2px dashed $dark;
    }
    &:hover {
      font-weight: 700;
    }
  }
  
  