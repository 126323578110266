@import 'styles/_theme.scss';

.secondaryHeaderWrapper {
  display: flex;
  justify-content: space-between;
}

.secondaryHeaderText {
  margin-top: $ss-24;
}

.noDelegatesText {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: italic;
  color: $neutralDark;
  font-size: $ts-18;
}

.addNewDiv{
  margin-top: $ss-40;
}