@import 'styles/_theme.scss';

.accountOverviewCard {
  margin-top: 0em !important;
  padding: 0em;
  align-items: center;
  height: 114px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 17px;
  margin-bottom: $ss-12;
  &:hover {
    background-color: $neutralXXLight;
    border: 1px solid $primaryDark;
  }
}

.cardActionArea {
  color: $primary;
  height: 100%;
}

.cardHeaderText {
  font-weight: 500;
  margin-bottom: $ss-4;
  margin-left: $ss-8;
  color: $neutralDark;
  font-size: $ts-20;
}

.lightGrayType {
  color: $neutral;
  margin-left: $ss-8;
  font-size: $ts-16;
}

.icon {
  margin-left: 20px;
  margin-right: 44px;
}

// Specifically for the "User" type fields, intended to be replaced when
// styles stabilize
.userCard {
  height: 100%;
}

.userCardHeader {
  padding: $ss-24 0 0 $ss-24;
  text-align: left;
  color: $neutralDark;

  &.MuiCardHeader-title {
    font-size: $hs-2;
  }
}

.userCardContent {
  padding: $ss-24;
}

// For cards on User Management, not sure if these will replace all of the v1s above
.userCardv2 {
  margin-top: $ss-30;
  border: 1px solid $neutralXLight;
  border-radius: 8px;
  box-shadow: 0px 3px 6px $darkShadow;
}

.userCardHeaderv2 {
  padding: $ss-18 0 $ss-18 $ss-36;
  text-align: left;
  color: $neutralDark;
  background-color: $neutralXLight;
  border-bottom: 1px solid $neutralLight;

  &.MuiCardHeader-title {
    font-size: $hs-2;
  }
}

.userCardContentv2 {
  padding: $ss-24;
}

// Specifically for the "OrganizationManagement" fields, intended to be
// replaced when styles stabilize
// Note: #e6e7e8 doesn't match any styled color.
// Note: `flex-basis` is what causes the grid/flex to expand to full width
.orgCard {
  border: 0.063em solid #e6e7e8;
  margin-bottom: 3.125em;
  flex-basis: 100%;
}

.orgCardHeader {
  padding-left: 2.5em;
  padding-top: 1.625em;
  padding-bottom: 1.625em;
  padding-right: 2.5em;

  .MuiCardHeader-title {
    color: $red;
  }

  .MuiCardHeader-action {
    margin-top: 0.2em;
    margin-right: 0em;
  }
}

// Err, probably belongs in the typography component.
.orgCardHeaderText {
  font-size: $ts-30;
  letter-spacing: 0em;
  color: $dark;
}

.styledCard {
  padding: $ss-24;
}

.styledCardContent {
  padding: $ss-24;
}

.panel {
  padding: $ss-16;
  height: 100%;
}

.cardHeader {
  padding: $ss-18 0 $ss-18 $ss-36;
  text-align: left;
  color: $primaryDark;
  background-color: $neutralXLight;
  border-bottom: 1px solid $neutralLight;

  &.MuiCardHeader-title {
    font-size: $hs-2;
  }
}
.cardV2 {
  margin-top: $ss-30;
  border: 1px solid $neutralLight;
  border-radius: 8px;
  box-shadow: 0px 3px 6px $lightShadow;
}

.cardContent {
  padding: $ss-40;
}
